import ReactGa from "react-ga4";

export const initGA = () => {
  ReactGa.initialize([
    {
      trackingId: process.env.REACT_APP_GOOGLE_LEADS_TAG,
    },
    { trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID },
  ]);
};
