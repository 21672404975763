import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./utils/language/i18nextinit";
import App from "./App";
//import { Blocks } from "react-loader-spinner";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

//const App = React.lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <BrowserRouter>{<App />}</BrowserRouter>
  </HelmetProvider>
);
