import React, { useEffect } from "react";
import styles from "./success.module.css";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import thankYou from "../../assets/images/thank_you.webp";
import { initGA } from "../../utils/ga-utils";

export function Success() {
  const { t } = useTranslation();
  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_LEADS_TAG) {
      initGA();
      // window.gtag("config", process.env.REACT_APP_GOOGLE_LEADS_TAG);
      window.gtag("event", "conversion", {
        send_to: process.env.REACT_APP_GOOGLE_LEADS_TAG,
      });
    }
  }, []);
  return (
    <div>
      <Container maxWidth={false}>
        <Link className={styles.link} to="/">
          <div className={styles.message}>{t("thank_you")}</div>
          <img className={styles.img} src={thankYou} alt={"Thank you"} />
          <p>{t("thank_you_1")}</p>
        </Link>
      </Container>
    </div>
  );
}
