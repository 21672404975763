import React, { useEffect, useState } from "react";
import styles from "./mainmenu.module.css";
import { /*Link,*/ animateScroll as scroll, scroller } from "react-scroll";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.png";
import { Drawer } from "@mui/material";
import classNames from "classnames";
import LanguageSelect from "../../utils/language/LanguageSelect";
import zvw from "../../assets/images/zvw.svg";
import { NavLink } from "react-router-dom";
export function MainMenu() {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };

  const burgerClass = classNames(styles.burger, {
    [styles[`open`]]: open,
  });
  const burgerClassS = classNames(styles.burgerSpan, {
    [styles[`open`]]: open,
  });
  const scopeClass = classNames(styles.downloadLink, styles.scope);
  const productLink = classNames(styles.downloadLink, styles.product);

  const pdfMobLink = classNames(styles.navItem, styles.pdfMobWrap);

  const { t } = useTranslation();

  const [pdfPath, setPdfPath] = useState("booklet_eng.pdf");
  const [pdfScope, setPdfScope] = useState("scope_of_services_eng.pdf");

  useEffect(() => {
    window.addEventListener("resize", () => setOpen(false));
    window.addEventListener("scroll", () => setOpen(false));
    if (localStorage.getItem("i18nextLng").startsWith("de")) {
      setPdfPath("booklet.pdf");
      setPdfScope("scope_of_services.pdf");
    }
  }, []);

  const Menu = () => {
    return (
      <div className={styles.navWrap}>
        <ul className={styles.navList}>
          <li>
            <NavLink
              onClick={() => {
                scroll.scrollToTop();
              }}
              to="/"
              className={styles.navItemLogo}
            >
              <div className={styles.logo}>
                <img
                  className={styles.logoImg}
                  src={logo}
                  alt={"company logo"}
                />
              </div>
            </NavLink>
          </li>
          <li className={styles.navItem}>
            <NavLink
              className={styles.link}
              /*activeClass="active"*/
              to="/"
              /*spy={true}
              smooth={true}
              offset={-100}
              duration={500}*/
              onClick={() => {
                setTimeout(() => {
                  scroller.scrollTo("apps", {
                    duration: 1500,
                    delay: 100,
                    smooth: true,
                    //offset: -50,
                    offset: -100,
                  });
                }, 1);
              }}
            >
              {t("header.app_header")}
            </NavLink>{" "}
          </li>

          <li className={styles.navDivider}></li>
          <li className={styles.navItem}>
            {" "}
            <NavLink
              className={styles.link}
              to="/"
              /*spy={true}
              smooth={true}
              offset={-100}
              duration={500}*/
              onClick={() => {
                setTimeout(() => {
                  scroller.scrollTo("cloud", {
                    duration: 500,
                    delay: 100,
                    smooth: true,
                    offset: -100,
                  });
                }, 1);
              }}
            >
              {t("header.web_header")}
            </NavLink>
          </li>
          <li className={styles.navDivider}></li>
          <li className={styles.navItem}>
            {" "}
            <NavLink
              className={styles.link}
              to="/"
              onClick={() => {
                setTimeout(() => {
                  scroller.scrollTo("cloud", {
                    duration: 500,
                    delay: 100,
                    smooth: true,
                    offset: -100,
                  });
                }, 1);
              }}
            >
              {t("header.cloud_header")}
            </NavLink>
          </li>
          <li className={styles.navDivider}></li>
          <li className={styles.navItem}>
            <NavLink
              className={styles.link}
              to="/"
              onClick={() => {
                setTimeout(() => {
                  scroller.scrollTo("chat", {
                    duration: 500,
                    delay: 100,
                    smooth: true,
                    offset: -100,
                  });
                }, 1);
              }}
            >
              {t("header.chat_header")}
            </NavLink>
          </li>
          <li className={styles.navDivider}></li>
          <li className={styles.navItem}>
            <NavLink
              className={styles.link}
              to="/"
              onClick={() => {
                setTimeout(() => {
                  /*scroll.scrollToTop();*/
                  scroller.scrollTo("payment", {
                    duration: 500,
                    delay: 100,
                    smooth: true,
                    offset: -100,
                  });
                }, 1);
              }}
            >
              {t("header.payment_header")}
            </NavLink>
          </li>
          <li className={pdfMobLink}>
            <PdfLink />
          </li>

          <li className={styles.pdfMobWrap}>
            <a href={"https://zvwhome.com"} className={styles.mobLink}>
              <p className={styles.mobLinkTextTop}>{t("header.product")}</p>
              <img
                src={zvw}
                alt={"zvwhome logo"}
                className={styles.zvwLogoDark}
              />
              <p className={styles.mobLinkTextBottom}>ZVW HOME CO. LTD.</p>
            </a>
          </li>
        </ul>
      </div>
    );
  };

  const PdfLink = () => {
    return (
      <>
        <a
          className={scopeClass}
          href={process.env.PUBLIC_URL + pdfScope}
          target="_blank"
          rel="noreferrer"
        >
          {t("header.scope_button")}
        </a>
        <a
          className={productLink}
          href={process.env.PUBLIC_URL + pdfPath}
          target="_blank"
          rel="noreferrer"
        >
          {t("header.product_button")}
        </a>
      </>
    );
  };

  return (
    <>
      <nav className={styles.header}>
        <div className={styles.mobileMenu}>
          <div className={styles.burgerBtn}>
            <button className={burgerClass} onClick={showDrawer}>
              <span className={burgerClassS}></span>
              <span className={styles.burgerSpan}></span>
              <span className={styles.burgerSpan}></span>
            </button>
          </div>

          <div
            className={styles.mobileLogo}
            onClick={() => {
              onClose();
              scroll.scrollToTop();
            }}
          >
            <img
              className={styles.mobileLogoImg}
              src={logo}
              alt={"company logo"}
            />
          </div>
          <LanguageSelect />
        </div>
        <div className={styles.desktopMenu}>
          {/*<div className={styles.logo} onClick={scroll.scrollToTop}>
            <img className={styles.logoImg} src={logo} alt={"company logo"} />
          </div>*/}

          <Menu />
        </div>
      </nav>
      <div className={styles.pdfWrapDesktop}>
        <PdfLink />
      </div>

      <Drawer sx={{ zIndex: 0 }} onClose={onClose} open={open}>
        <Menu />
      </Drawer>
    </>
  );
}
