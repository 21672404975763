import React, { lazy, useEffect, useState, Suspense } from "react";
import styles from "./App.module.css";
import LanguageSelect from "./utils/language/LanguageSelect";
import { MainMenu } from "./components/MainMenu";
//import { Packages } from "./components/Packages";
//import { Main } from "./components/Main";
import { Footer } from "./components/Footer";
import { Route, Routes } from "react-router-dom";
import { NoMatch } from "./components/NoMatch";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { initGA } from "./utils/ga-utils";
import { Success } from "./components/Success";

const Packages = lazy(() => import("./components/Packages/Packages"));
const Main = React.lazy(() => import("./components/Main/Main"));

function App() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(localStorage.getItem("i18nextLng"));

  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_LEADS_TAG) {
      initGA();
    }
  };

  useEffect(() => {
    if (selected !== "en") {
      if (!selected.startsWith("de")) {
        setSelected("en");
        localStorage.setItem("i18nextLng", "en");
      } else {
        localStorage.setItem("i18nextLng", "de");
        setSelected("de");
      }
    }
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  const handleDeclineCookie = () => {
    Cookies.remove("_ga", { domain: ".converse-x-chat.com" });
    Cookies.remove("_dat", { domain: ".converse-x-chat.com" });
    Cookies.remove("_gid", { domain: ".converse-x-chat.com" });
    Cookies.remove(`_ga_${process.env.REACT_APP_GOOGLE_ANALYTICS_TAG}`, {
      domain: ".converse-x-chat.com",
    });
  };

  const germanDescription =
    "Vedienen Sie Geld mit Ihrem eigenen Online-Chat! Wir bieten eine Komplettloesung an, welche es Ihnen erlaubt, sich mit Ihrem eigenen Dating-Portal inklusive angebundener APPs selbstaendig zu machen! Zu unserem Komplettloesung gehoert Ihre eigene Cloud, Ihr eigener Kontaktmarkt - natuerlich sowohl mit iOS als auch mit Android APP, ein umfassendes Chat-Tool sowie ein Verwaltungs-Backend, mit dem Sie Ihre Kunden und Mitarbeiter jederzeit effizient im Blick haben - und All das zu einem unschlagbaren Festpreis!";
  const englishDescription =
    "Earn money with your own online chat! We offer a complete solution that allows you to become self-employed with your own dating portal including connected APPs! Our complete solution includes your own cloud, your own contact market - of course with both iOS and Android APP - a comprehensive chat tool and an administration backend with which you can keep an eye on your customers and employees efficiently at all times - and all of that for an unbeatable fixed price!";
  const germanKeywords =
    "Chat, Software, Chat-Backend, kostenpflichtiger Chat, Kontaktmarkt, Dating-APP, iOS, Android, online Geld verdienen, Chat-Agent, Chatbetreiber, Chatsoftware, Homeoffice, Arbeiten von zu Hause,";
  const englishKeywords =
    "Chat, Software, Chat-Backend, paid Chat, contact market, dating market, Dating-APP, iOS, Android, earn money online, Chat Agent, Chat operator, Chatsoftware, Homeoffice, working from home,";

  return (
    <div>
      <div id="mainApp" className={styles.App}>
        <Helmet>
          {selected === "de" && (
            <meta name="keywords" content={germanKeywords} />
          )}
          {selected === "de" && <html lang="de" />}
          {selected === "de" && (
            <meta name="description" content={germanDescription} />
          )}

          {selected === "en" && <html lang="en" />}

          {selected === "en" && (
            <meta name="keywords" content={englishKeywords} />
          )}
          {selected === "en" && (
            <meta name="description" content={englishDescription} />
          )}
        </Helmet>
        <Suspense
          fallback={
            <div className={"fallback"}>
              <img
                className={"fbImg"}
                src={process.env.PUBLIC_URL + "logo512.webp"}
                alt={"logo"}
              />
              {/*<Blocks
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
          />*/}
            </div>
          }
        >
          <div className={styles.language}>{<LanguageSelect />}</div>

          <div className={styles.mainMenu}>{<MainMenu />}</div>
          <CookieConsent
            buttonText={t("cookie_accept")}
            declineButtonText={t("cookie_reject")}
            enableDeclineButton
            flipButtons
            debug={false}
            containerClasses="cookie__container"
            buttonWrapperClasses="cookie__buttons-wrapper"
            buttonClasses="cookie__accept-button"
            declineButtonClasses="cookie__reject-button"
            contentClasses="cookie__content"
            onAccept={handleAcceptCookie}
            onDecline={handleDeclineCookie}
          >
            {t("cookie")}
          </CookieConsent>
          <Routes>
            <Route index path="/" element={<Main />} />
            <Route index path="/packages" element={<Packages />} />
            <Route index path="/success" element={<Success />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>

          {<div className={styles.water}></div>}
          {<Footer />}
        </Suspense>
      </div>
    </div>
  );
}

export default App;
