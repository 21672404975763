import React, { useEffect, useRef, useState } from "react";
import styles from "./footer.module.css";
import logo from "../../assets/icons/ZVWHome.webp";
import inLink from "../../assets/icons/linkedin.png";
import xing from "../../assets/icons/xing.webp";
import fb from "../../assets/icons/fb.webp";
import inst from "../../assets/icons/instagram.webp";
import twit from "../../assets/icons/twit.png";
import mail from "../../assets/icons/email.png";
import phone from "../../assets/icons/phone.webp";
import classNames from "classnames";
import { animateScroll as scroll } from "react-scroll";
import { useClickAway } from "react-use";
//import Chatra from "@chatra/chatra";
//import Chatra from "@chatra/chatra";
export function Footer() {
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  const chatStyle = classNames(styles.chatBlock, { [styles.active]: active });
  useClickAway(ref, () => {
    setActive(false);
  });
  let Chatra = require("@chatra/chatra");
  let config = {
    setup: {
      mode: "frame",
      injectTo: "footer",
      //startHidden: true,
      customWidgetButton: "styles.chatBtn",
      disableChatOpenHash: true,
      colors: {
        buttonText: "#fff",
        buttonBg: "#ef6324",
        clientBubbleBg: "#58b2ce",
        agentBubbleBg: "#5d6db1",
      },
    },
    ID: `${process.env.REACT_APP_CHAT_PUBLIC_KEY}`,
  };
  useEffect(() => {
    Chatra("init", config);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.chatContainer}>
          <div className={styles.chatWrap} ref={ref}>
            <button
              className={styles.chatBtn}
              onClick={() => setActive(!active)}
            >
              LIVECHAT
            </button>
            <div className={chatStyle} id={"footer"}></div>
          </div>
        </div>
        <div className={styles.logoContainer} onClick={scroll.scrollToTop}>
          <img className={styles.logoImg} src={logo} alt={"company logo"} />
          <p className={styles.logoText}>
            &#169; Copyright by ZvW Home Co.Ltd | 2023
          </p>
          <div>
            <p className={styles.logoTextMob}>
              &#169; Copyright by ZvW Home Co.Ltd
            </p>

            <p className={styles.logoTextYear}>2023</p>
          </div>
        </div>
        <div className={styles.socialContainer}>
          <a
            rel="noreferrer"
            className={styles.socialLink}
            href={"https://www.linkedin.com/in/elias-von-wildenradt-417858270/"}
            target={"_blank"}
          >
            <img
              className={styles.socialImg}
              src={inLink}
              alt={"Linkedin link"}
            />
          </a>
          <a
            rel="noreferrer"
            className={styles.socialLink}
            href={"https://www.xing.com/profile/Elias_vonWildenradt"}
            target={"_blank"}
          >
            <img className={styles.socialImg} src={xing} alt={"Xing link"} />
          </a>{" "}
          <a
            className={styles.socialLink}
            href={
              "https://www.facebook.com/profile.php?id=100092211437760&mibextid=LQQJ4d"
            }
            target={"_blank"}
            rel="noreferrer"
          >
            <img className={styles.socialImg} src={fb} alt={"Facebook link"} />
          </a>
          <a
            className={styles.socialLink}
            rel="noreferrer"
            href={"https://instagram.com/converse_x_chat"}
            target={"_blank"}
          >
            <img
              className={styles.socialImg}
              src={inst}
              alt={"Instagram link"}
            />
          </a>
          <a
            rel="noreferrer"
            className={styles.socialLink}
            href={"https://twitter.com/ConverseX2023"}
            target={"_blank"}
          >
            <img className={styles.socialImg} src={twit} alt={"Twitter link"} />
          </a>
          <a
            className={styles.socialLink}
            href="mailto:info@zvwhome.com"
            rel="noreferrer"
          >
            <img className={styles.socialImg} src={mail} alt={"Email link"} />
          </a>
          <a
            className={styles.socialLink}
            href="tel:+66659194896"
            rel="noreferrer"
          >
            <img className={styles.socialImg} src={phone} alt={"Phone link"} />
          </a>
        </div>
      </div>
    </div>
  );
}
