import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import styles from "./languageselect.module.css";

import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Icon from "../../assets/images/flag.png";
import Icon1 from "../../assets/images/flag1.png";
import zvw from "../../assets/images/zvw.png";

import zvwColor from "../../assets/images/zvw.svg";

const LanguageSelect = () => {
  const languageMap = {
    en: {
      label: <img src={Icon} alt={"english language"} />,
      dir: "ltr",
      active: true,
      lan: "en",
    },
    de: {
      label: <img src={Icon1} alt={"deutsche sprache"} />,
      dir: "ltr",
      active: false,
      lan: "de",
    },
  };
  let selected = localStorage.getItem("i18nextLng") || "en";
  if (selected.startsWith("de")) {
    selected = "de";
  } else if (selected.startsWith("en")) {
    selected = "en";
  } else {
    selected = "en";
  }
  //const [url, setUrl] = useState(selected);
  // useEffect(() => {
  //    i18next.changeLanguage(selected);
  // }, []);

  /*useEffect(() => {
    const curUrl = window.location.pathname;
    if (curUrl.endsWith("/en") || curUrl.endsWith("/de")) {
      if (
        curUrl.replaceAll("/", "") !== "de" &&
        curUrl.replaceAll("/", "") !== "en"
      ) {
        newUrl = "";
        newUrl = window.location.origin + `/${selected}`;

        window.location.replace(newUrl);
        i18next.changeLanguage(selected);
      } else if (curUrl.replaceAll("/", "") !== selected) {
        i18next.changeLanguage(curUrl.replaceAll("/", ""));
        newUrl = window.location.origin + `/${curUrl.replaceAll("/", "")}`;
        window.location.replace(newUrl);
      }
    } else {
      window.history.pushState(
        {},
        null,
        window.location.origin + `/${selected}`
      );
    }
  }, []);*/

  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);
  let newUrl;
  return (
    <span className={styles.but}>
      <Button
        sx={{
          transform: "translateY(1px)",
          paddingBottom: 0,
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          //          justifyContent: "right",
          minWidth: "31px !important",
        }}
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
      >
        {languageMap[selected].label}
        <div className={styles.arrow}>
          <ArrowDropDown fontSize="small" style={{ verticalAlign: "middle" }} />
        </div>
      </Button>
      <a className={styles.link} href={"https://zvwhome.com"}>
        <img src={zvw} alt={"zvwhome logo"} className={styles.zvwLogo} />
        <img className={styles.logoColor} src={zvwColor} alt={"ada"} />
      </a>

      <Popover
        disableScrollLock
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={{ background: "linear-gradient(#ffffff, #868686)" }}>
          <List>
            {/*<ListSubheader style={{ background: " #868686" }}>
              {t("select_language")}
            </ListSubheader>*/}
            {Object.keys(languageMap)?.map(
              (item) =>
                languageMap[item].lan !== selected && (
                  <ListItem
                    button
                    key={item}
                    onClick={() => {
                      //newUrl = "";
                      //newUrl = window.location.origin + `/${item}`;
                      //window.history.pushState({}, null, newUrl);
                      //window.location.replace(newUrl);
                      i18next.changeLanguage(item);
                      setMenuAnchor(null);
                      window.location.reload();
                    }}
                  >
                    {languageMap[item].label}
                  </ListItem>
                )
            )}
          </List>
        </div>
      </Popover>
    </span>
  );
};

export default LanguageSelect;
